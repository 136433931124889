
import { IAbstractConnectorOptions } from "../../helpers";

interface IBinanceOptions extends IAbstractConnectorOptions {
  supportedChainIds: Array<number>;
}

const ConnectToBinance = async (
  BinanceProvider: any,
  opts: IBinanceOptions
) => {
  const provider = new BinanceProvider(opts.supportedChainIds);

  await provider.activate();
  await provider.getAccount();
  await provider.getChainId();

  return provider;
};

export default ConnectToBinance;
