import * as React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import close from './close.png';
import Button from './Button.png';

import { Provider } from "./Provider";
import {
  MODAL_LIGHTBOX_CLASSNAME,
  MODAL_CONTAINER_CLASSNAME,
  MODAL_HITBOX_CLASSNAME,
  MODAL_CARD_CLASSNAME
} from "../constants";
import { SimpleFunction, IProviderUserOptions, ThemeColors } from "../helpers";





declare global {
  // tslint:disable-next-line
  interface Window {
    ethereum: any;
    web3: any;
    updateWeb3Modal: any;
  }
}

interface ILightboxStyleProps {
  show: boolean;
  offset: number;
  opacity?: number;
}

const SLightbox = styled.div<ILightboxStyleProps>`
  transition: opacity 0.1s ease-in-out;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: ${({ offset }) => (offset ? `-${offset}px` : 0)};
  left: 0;
  z-index: 2;
  will-change: opacity;
  background-color: ${({ opacity }) => {
    let alpha = 0.4;
    if (typeof opacity === "number") {
      alpha = opacity;
    }
    return `rgba(0, 0, 0, ${alpha})`;
  }};
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  display: flex;
  justify-content: center;
  align-items: center;

  & * {
    box-sizing: border-box !important;
  }
`;

interface IModalContainerStyleProps {
  show: boolean;
  themeColors: ThemeColors;

}
interface IModalContainerClose {
  close: string;
}

const SModalContainer = styled.div<IModalContainerStyleProps>`
  position: relative;
  width: 25vw;
  height: 70vh;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  border: 2px solid #FED512;
  border-radius: 20px;
  background-color: ${({ themeColors }) => themeColors.background};
  @media screen and (max-width: 768px) {
    width: 90vw;
  }
  
`;

const SHitbox = styled.div<IModalContainerClose>`
  position: absolute;
  top: -3%;
  left: 94%;
  right: 0;
  bottom: 0;
  cursor: pointer;
  width: 26.3vw;
  height: 52vh;
  z-index: 10;
  background:url(${close}); 
  background-repeat: no-repeat;
  @media screen and (max-width: 768px) {
    width: 15vw;
    height: 10vh;
    left: 92%;
    top: -5%;
    transform: scale(0.7);
  }
`;

interface IModalCardStyleProps {
  show: boolean;
  themeColors: ThemeColors;
  maxWidth?: number;
}

const SModalCard = styled.div<IModalCardStyleProps>`
  position: relative;
  width: 100%;
  background-color: ${({ themeColors }) => themeColors.background};
  padding: 0;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};

  display: grid;
  grid-template-columns: repeat(2, minmax(11vw, 1fr));
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}vw` : "40vw")};
  min-width: fit-content;
  max-height: 100%;
  overflow: auto;
  border-bottom: 1px solid #fff;

  @media screen and (max-width: 768px) {
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}vw` : "25vw")};
    grid-template-columns: 1fr;
  }
`;

interface IModalProps {
  themeColors: ThemeColors;
  userOptions: IProviderUserOptions[];
  onClose: SimpleFunction;
  resetState: SimpleFunction;
  lightboxOpacity: number;
}

interface IModalState {
  show: boolean;
  lightboxOffset: number;
}

const SModalTitle = styled.div`
  font-size: 5vh;
  color: #fff;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #fff;
  padding-bottom: 2vh;
`
const SModalQ = styled.div`
  font-size: 3vh;
  color: #fff;
  margin: 3vh 0 0 0;
  @media screen and (max-width: 768px) {
    font-size: 2.2vh;
  }


`
const SModalBtn = styled.div`
    width: 20vw;
    height: 8vh;
    margin: 3vh 0 0 0;
    background: url('${Button}');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.7);
    cursor: pointer;
    user-select: none;
    font-size: 2vh;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
      width: 50vw;
    }
`
const INITIAL_STATE: IModalState = {
  show: false,
  lightboxOffset: 0
};

export class Modal extends React.Component<IModalProps, IModalState> {
  constructor(props: IModalProps) {
    super(props);
    window.updateWeb3Modal = async (state: IModalState) => {
      this.setState(state);
    };
  }
  public static propTypes = {
    userOptions: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    resetState: PropTypes.func.isRequired,
    lightboxOpacity: PropTypes.number.isRequired
  };

  public lightboxRef?: HTMLDivElement | null;
  public mainModalCard?: HTMLDivElement | null;

  public state: IModalState = {
    ...INITIAL_STATE
  };

  public componentDidUpdate(prevProps: IModalProps, prevState: IModalState) {
    if (prevState.show && !this.state.show) {
      this.props.resetState();
    }
    if (this.lightboxRef) {
      const lightboxRect = this.lightboxRef.getBoundingClientRect();
      const lightboxOffset = lightboxRect.top > 0 ? lightboxRect.top : 0;

      if (
        lightboxOffset !== INITIAL_STATE.lightboxOffset &&
        lightboxOffset !== this.state.lightboxOffset
      ) {
        this.setState({ lightboxOffset });
      }
    }
  }

  public render = () => {
    const { show, lightboxOffset } = this.state;

    const { onClose, lightboxOpacity, userOptions, themeColors } = this.props;

    return (
      <SLightbox
        className={MODAL_LIGHTBOX_CLASSNAME}
        offset={lightboxOffset}
        opacity={lightboxOpacity}
        ref={c => (this.lightboxRef = c)}
        show={show}
      >
        <SModalContainer className={MODAL_CONTAINER_CLASSNAME} show={show} themeColors={themeColors}>
          <SModalTitle>Connect Wallet</SModalTitle>
          <SHitbox className={MODAL_HITBOX_CLASSNAME} onClick={onClose} close={close}></SHitbox>
          <SModalCard
            className={MODAL_CARD_CLASSNAME}
            show={show}
            themeColors={themeColors}
            maxWidth={userOptions.length < 3 ? 25 : 40}
            ref={c => (this.mainModalCard = c)}
            
          >
            {userOptions.map(provider =>
              !!provider ? (
                <Provider
                  name={provider.name}
                  logo={provider.logo}
                  description={provider.description}
                  themeColors={themeColors}
                  onClick={provider.onClick}
                />
              ) : null
            )}
          </SModalCard>
          <SModalQ>Haven't got a crypto wallet yet?</SModalQ>
          <SModalBtn onClick={() => window.open("https://docs.binance.org/smart-chain/wallet/binance.html")}>Learn How to Connect
          </SModalBtn>
        </SModalContainer>
      </SLightbox>
    );
  };
}
